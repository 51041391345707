<template>
  <div class="container-fluid full-width-page-wrapper steps-container-wrapper">
    <div class="data-entry">
      <div class="row input-row">
        <div class="col-12">
          <ifac-loader v-if="busy"></ifac-loader>
          <ifac-report-summary
            v-else
            with-submission
            :report="report"
            :editable="false"
            :uncompleted-sections="uncompletedSections(report)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Reports from '@/services/Api/Reports';
import {
  IfacLoader,
  IfacReportSummary,
} from '@ifac/ui';

export default {
  name: 'ConferenceReportDetails',
  components: {
    IfacLoader,
    IfacReportSummary,
  },
  created() {
    this.fetchReport();
  },
  computed: {
    ...mapState({
      metas: (state) => state.report.metas,
    }),
    ...mapGetters({
      uncompletedSections: 'report/uncompletedSections',
    }),
    conferenceId() {
      return this.$route.params.id;
    },
    reportId() {
      return this.$route.params.reportId;
    },
  },
  data() {
    return {
      busy: false,
      report: null,
    };
  },
  methods: {
    async fetchReport() {
      this.busy = true;
      try {
        const response = await Reports.get(this.reportId);
        const report = response?.data?.data;

        if (response?.status === 200 && report) {
          this.report = report;
        }
      } catch (error) {
        console.log(error);
      }
      this.busy = false;
    },
  },
};
</script>
